import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/hayden/dev/fluidsolarthermal.com/web/src/components/layout-markdown.tsx";
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import SEO from '../components/seo';
import Tour from '../components/tour';
export const pageQuery = graphql`
  query CountryHome {
    country18: modularHomesJson(slug: { eq: "country-home-18-segment" }) {
      ...HomeFields
      ...HomeFieldsImages
    }
    country24: modularHomesJson(slug: { eq: "country-home-24-segment" }) {
      ...HomeFields
      ...HomeFieldsImages
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Home solutions" mdxType="SEO" />
    <h1 {...{
      "id": "country-home"
    }}>{`Country Home`}</h1>
    <p>{`Designed to be spacious and comfortable whilst meeting the challenges of rural living, our Country Home is meant for permanent occupation and includes the safety measures provided by our Refuge models. This design can be constructed from materials rated for one hour of fire and heat exposure, giving your family and belongings better safety in the event of bushfires.`}</p>
    <div className="mb-3">
  <Tour external={true} internal={true} modularHomesJson={props.data.country18} mdxType="Tour"></Tour>
    </div>
    <div className="flex flex-col items-center">
  <Link to="/modular-homes/country-home-18-segment" className="text-xl outline-button move-up" mdxType="Link">
    Pricing information
  </Link>
    </div>
    <p>{`Like all our housing solutions, the Country Home is built for maximum energy efficiency, which also means that you receive greater personal comfort. The building is designed to maintain an even temperature all year round, keeping you relatively cool and relaxed in the summer and warm through the winter, which also substantially reduces energy consumption and cost. Once ordered, your build can be transported on the width of a standard trailer and assembled in only a few days. Our patented solar-thermal technology means that these spaces can supply their own reliable, sustainably-sourced energy, and are capable of operating completely free of the grid.`}</p>
    <div className="mb-3">
  <Tour external={false} internal={true} modularHomesJson={props.data.country24} mdxType="Tour"></Tour>
    </div>
    <div className="flex flex-col items-center">
  <Link to="/modular-homes/country-home-24-segment" className="text-xl outline-button move-up" mdxType="Link">
    Pricing information
  </Link>
    </div>
    <p><strong parentName="p">{`Please Note: In a bushfire or other natural disaster, our Country Homes cannot guarantee safety in all circumstances and should not be a replacement for a comprehensive bushfire plan. Always heed evacuation warnings and other safety advice from the CFS, RFS and other Emergency Services.`}</strong></p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      